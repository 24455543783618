export default function Icon({ size = 46, color = "#6C63FF" }) {
  return (
    <>
      <a
      className="icon-contact"
        href="https://api.whatsapp.com/send?phone=541168012755&amp;text=Hi%20Ache1%2C%20I%20hav
              e%20a%20project%20and%20I%20want%20that%20you%20help%20me"
              target="_blank"
              rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 46 42"
          width={size}
          height={size}
          fill={color}
        >
          <path
            d="M0.500003 14.2365C0.498817 12.464 0.847294 10.7086 1.52548 9.07094C2.20366 7.43326 3.19823 5.94545 4.45224 4.6927C5.70624 3.43995 7.19505 2.44687 8.8334 1.77033C10.4717 1.09378 12.2275 0.747059 14 0.750019H32C39.4543 0.750019 45.5 6.81377 45.5 14.2365V41.25H14C6.54575 41.25 0.500003 35.1863 0.500003 27.7635V14.2365ZM41 36.75V14.2365C40.9941 11.8526 40.0435 9.5683 38.3566 7.88391C36.6696 6.19951 34.3839 5.25239 32 5.25002H14C12.8184 5.24706 11.6479 5.47738 10.5555 5.92778C9.46308 6.37818 8.47035 7.03979 7.63421 7.87468C6.79807 8.70956 6.13497 9.7013 5.68293 10.793C5.23089 11.8847 4.99882 13.0549 5 14.2365V27.7635C5.00596 30.1474 5.95651 32.4317 7.64343 34.1161C9.33036 35.8005 11.6161 36.7476 14 36.75H41ZM27.5 18.75H32V23.25H27.5V18.75ZM14 18.75H18.5V23.25H14V18.75Z"
            fill={color}
          />
        </svg>
      </a>
    </>
  );
}
