import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./routes/App";
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-234226195-1";
ReactGA.initialize(TRACKING_ID)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
