
const ContainerHeader = ({children}) =>{
    return (
        <>
            <div className="header">
                {children}
            </div>
        </>
    )
}
export default ContainerHeader;