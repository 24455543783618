const ContainerLeftSection = ({ children }) => {
  return (
    <>
      <div className="container-section leftside-section">
        <div className="content_container-section">
            {children}
        </div>
      </div>
    </>
  );
};
export default ContainerLeftSection;
